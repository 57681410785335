import React from 'react';
import { Container } from 'react-bootstrap';
const TermsConditions = () => {
	return (
		<Container>
			<p>
				<span>Last updated March 01, 2024</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>AGREEMENT TO OUR LEGAL TERMS</b>
				</span>
			</p>
			<p>
				<span>We are </span>
				<span>
					<b>Combined Logics</b>
				</span>
				<span>
					&nbsp;(&lsquo;Company&rsquo;, &lsquo;we&rsquo;, &lsquo;us&rsquo; or
					&lsquo;our&#39;), a company registered in India at C-84, Janki Puri,
					Near Chanakya Puri, Uttam Nagar, West Delhi, New Delhi 110059, India.
				</span>
			</p>
			<p>
				<span>
					We operate the website https://sowtrendy.com/ (the
					&lsquo;Site&rsquo;), as well as any other related products and
					services that refer or link to these legal terms (the &lsquo;Legal
					Terms&rsquo;) (collectively, the &quot;Services&quot;).
				</span>
			</p>
			<p>
				<span>
					We provide the best quality materials directly from talented sellers
					at a low price.
				</span>
			</p>
			<p>
				<span>
					You can contact us by phone (WhatsApp) at +91981118052, or by email at
					sow.trendy@gmail.com or by mail to C-84, Janki Puri, Near Chanakya
					Puri, Uttam Nagar, West Delhi, New Delhi 110059, India.
				</span>
			</p>
			<p>
				<span>
					These Legal Terms constitute a legally binding agreement made between
					you. whether personally or on behalf of an entity (&lsquo;you&rsquo;).
					and Combined Logics, concerning your access to and use of the
					Services. You agree that by accessing the Services, you have read.
					understood, and agreed to be bound by all of these Legal Terms.
				</span>
			</p>

			<p>
				<span>
					IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE
					EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
					USE IMMEDIATELY.
				</span>
			</p>
			<p>
				<span>
					Supplemental terms and conditions or documents that may be posted on
					the Services from time to time are hereby expressly incorporated
					herein by reference. In our sole discretion, we reserve the right to
					make changes or modifications to these Legal Terms from time to time.
					We will alert you about any changes by updating the &lsquo;Last
					updated&rsquo; date of these Legal Terms, and you waive any right to
					receive specific notice of each such change. It is your responsibility
					to periodically review these Legal Terms to stay informed of updates.
					You will be subject to and will be deemed to have been made aware of
					and accepted, the changes in any revised Legal Terms by your continued
					use of the Services after the date such revised Legal Terms are
					posted.
				</span>
			</p>
			<p>
				<span>
					All users who are minors in the jurisdiction in which they reside
					(generally under the age of 18) must have the permission of, and be
					directly supervised by, their parent or guardian to use the Services.
					If you are a minor, you must have your parent or guardian read and
					agree to these Legal Terms before you use the Services.
				</span>
			</p>

			<p>
				<span>
					We recommend that you print a copy of these Legal Terms for your
					records.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>TABLE OF CONTENTS</b>
				</span>
			</p>
			<p>
				<span>1. OUR SERVICES</span>
			</p>
			<p>
				<span>2. INTELLECTUAL PROPERTY RIGHTS</span>
			</p>
			<p>
				<span>3. USER REPRESENTATIONS</span>
			</p>
			<p>
				<span>4, USER REGISTRATION</span>
			</p>
			<p>
				<span>&sect;. PRODUCTS</span>
			</p>
			<p>
				<span>6. PURCHASES AND PAYMENT</span>
			</p>
			<p>
				<span>7. RETURN POLICY</span>
			</p>
			<p>
				<span>8. PROHIBITED ACTIVITIES</span>
			</p>
			<p>
				<span>9. USER GENERATED CONTRIBUTIONS</span>
			</p>
			<p>
				<span>10. CONTRIBUTION LICENCE</span>
			</p>
			<p>
				<span>11. GUIDELINES FOR REVIEWS</span>
			</p>
			<p>
				<span>12. SOCIAL MEDIA</span>
			</p>
			<p>
				<span>13. SERVICES MANAGEMENT</span>
			</p>
			<p>
				<span>14. PRIVACY POLICY</span>
			</p>
			<p>
				<span>15. COPYRIGHT INFRINGEMENTS</span>
			</p>
			<p>
				<span>16. TERM AND TERMINATION</span>
			</p>
			<p>
				<span>17. MODIFICATIONS AND INTERRUPTIONS</span>
			</p>
			<p>
				<span>18. GOVERNING LAW</span>
			</p>
			<p>
				<span>19. DISPUTE RESOLUTION</span>
			</p>
			<p>
				<span>20. CORRECTIONS</span>
			</p>
			<p>
				<span>21. DISCLAIMER</span>
			</p>
			<p>
				<span>22. LIMITATIONS OF LIABILITY</span>
			</p>
			<p>
				<span>23. INDEMNIFICATION</span>
			</p>
			<p>
				<span>24. USER DATA</span>
			</p>
			<p>
				<span>25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span>
			</p>
			<p>
				<span>26. MISCELLANEOUS</span>
			</p>
			<p>
				<span>27. REFUND POLICY</span>
			</p>
			<p>
				<span>28. SHIPPING POLICY</span>
			</p>
			<p>
				<span>29. CONTACT US</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>1. OUR SERVICES</b>
				</span>
			</p>
			<p>
				<span>
					The information provided when using the Services is not intended for
					distribution to or use by any person or entity in any jurisdiction or
					country where such distribution or use would be contrary to law or
					regulation or which would subject us to any registration requirement
					within such jurisdiction or country. Accordingly, those persons who
					choose to access the Services from other locations do so on their
					initiative and are solely responsible for compliance with local laws,
					if and to the extent local laws are applicable.
				</span>
			</p>

			<p>
				<span>
					<b>2. INTELLECTUAL PROPERTY RIGHTS</b>
				</span>
			</p>
			<p>
				<span>
					<u>Our intellectual property</u>
				</span>
			</p>
			<p>
				<span>
					We are the owner or the licensee of all intellectual property rights
					in our Services, including all source code, databases, functionality,
					software, website designs, audio, video, text, photographs, and
					graphics in the Services (collectively, the &lsquo;Content&rsquo;), as
					well as the trademarks, service marks, and logos contained therein
					(the &lsquo;Marks&rsquo;).
				</span>
			</p>
			<p>
				<span>
					Our Content and Marks are protected by copyright and trademark laws
					(and various other intellectual property rights and unfair competition
					laws) and treaties in the India and around the world. The Content and
					Marks are provided in or through the Services &lsquo;AS-IS&#39; for
					your personal, non-commercial use only.
				</span>
			</p>

			<p>
				<span>
					Your use of our Services Subject to your compliance with these Legal
					Terms, including the &lsquo;PROHIBITED ACTIVITIES&rsquo; section
					below, we grant you a non-exclusive, non-transferable, revocable
					license to: = access the Services; and download or print a copy of any
					portion of the Content to which you have properly gained access,
					solely for your personal, non-commercial use. Except as set out in
					this section or elsewhere in our Legal Terms, no part of the Services
					and no Content or Marks may be copied, reproduced, aggregated,
					republished, uploaded, posted, publicly displayed, encoded,
					translated, transmitted, distributed, sold, licensed, or otherwise
					exploited for any commercial purpose whatsoever, without our express
					prior written permission.
				</span>
			</p>
			<p>
				<span>
					If you wish to make any use of the Services, Content, or Marks other
					than as set out in this section or elsewhere in our Legal Terms,
					please address your request to: sow.trendy@gmail.com.
				</span>
			</p>
			<p>
				<span>
					If we ever grant you permission to post, reproduce, or publicly
					display any part of our Services or Content, you must identify us as
					the owners or licensors of the Services, Content, or Marks and ensures
					that any copyright or proprietary notice appears or is visible on
					posting, reproducing, or displaying our Content.
				</span>
			</p>

			<p>
				<span>
					We reserve all rights not expressly granted to you in and to the
					Services, Content, and Marks. Any breach of these Intellectual
					Property Rights will constitute a material breach of our Legal Terms
					and your right to use our Services will terminate immediately.
				</span>
			</p>

			<p>
				<span>
					<u>Your submissions and contributions</u>
				</span>
			</p>
			<p>
				<span>
					Please review this section and the &lsquo;PROHIBITED ACTIVITIES&rsquo;
					section carefully before using our Services to understand the (a)
					rights you give us and (b) obligations you have when you post or
					upload any content through the Services. Submissions: By directly
					sending us any question, comment, suggestion, idea, feedback, or other
					information about the Services (&lsquo;Submissions&rsquo;), you agree
					to assign to us all intellectual property rights in such Submission.
					You agree that we shall own this Submission and be entitled to its
					unrestricted use and dissemination for any lawful purpose, commercial
					or otherwise, without acknowledgment or compensation to you.
					Contributions: The Services may invite you to chat, contribute to, or
					participate in blogs, message boards, online forums, and other
					functionality during which you may create, submit, post, display,
					transmit, publish, distribute, or broadcast content and materials to
					us or through the Services, including but not limited to text,
					writings, video, audio, photographs, music, graphics, comments,
					reviews, rating suggestions, personal information, or other material
					(&lsquo;Contributions&rsquo;). Any Submission that is publicly posted
					shall also be treated as a Contribution.
				</span>
			</p>
			<p>
				<span>
					You understand that Contributions may be viewable by other users of
					the Services. When you post Contributions, you grant us a license
					(including use of your name, trademarks, and logos): By posting any
					Contributions, you grant us an unrestricted, unlimited, irrevocable,
					perpetual, non-exclusive, transferable, royalty-free, fully-paid,
					worldwide right, and license to: use, copy, reproduce, distribute,
					sell, resell, publish, broadcast, retitle, store, publicly perform,
					publicly display, reformat, translate, excerpt (in whole or in part),
					and exploit your Contributions (including, without limitation, your
					image, name, and voice) for any purpose,
				</span>
			</p>
			<p>
				<span>
					commercial, advertising, or otherwise, to prepare derivative works of,
					or incorporate into other works, your Contributions, and to sublicence
					the licenses granted in this section. Our use and distribution may
					occur in any media format and through any media channels. This license
					includes our use of your name, company name, and franchise name, as
					applicable, and any of the trademarks, service marks, trade names,
					logos, and personal and commercial images you provide.
				</span>
			</p>
			<p>
				<span>
					You are responsible for what you post or upload: By sending us
					Submissions and/or posting Contributions through any part of the
					Services or making Contributions accessible through the Services by
					linking your account through the Services to any of your social
					networking accounts, you:
				</span>
			</p>
			<p>
				<span>
					* confirm that you have read and agree with our &lsquo;PROHIBITED
					ACTIVITIES&rsquo; and will not post, send, publish, upload, or
					transmit through the Services any Submission nor post any Illegal
					contribution, harassing, hateful, harmful, defamatory, obscene,
					bullying, abusive, discriminatory, threatening to any person or group,
					sexually explicit, false, inaccurate, deceitful, or misleading;
					&raquo; to the extent permissible by applicable law, waive any moral
					rights to any such Submission and/or Contribution;
				</span>
			</p>
			<p>
				<span>
					* warrant that any such Submission and/or Contributions are original
					to you or that you have the necessary rights and licenses to submit
					such Submissions and/or Contributions and that you have full authority
					to grant us the above-mentioned rights about your Submissions and/or
					Contributions; and
				</span>
			</p>
			<p>
				<span>
					* warrant and represent that your Submissions and/or Contributions do
					not constitute confidential information.
				</span>
			</p>
			<p>
				<span>
					You are solely responsible for your Submissions and/or Contributions
					and you expressly agree to reimburse us for any losses that we may
					suffer because you breach (a) this section, (b) any third party&#39;s
					intellectual property rights, or (c) applicable law.
				</span>
			</p>
			<p>
				<span>
					We may remove or edit your Content: Although we have no obligation to
					monitor any Contributions, we shall have the right to remove or edit
					any Contributions at any time without notice if, in our reasonable
					opinion, we consider such Contributions harmful or in breach of these
					Legal Terms. If we remove or edit any such Contributions, we may also
					suspend or disable your account and report you to the authorities.
				</span>
			</p>

			<p>
				<span>
					<u>Copyright infringement</u>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					We respect the intellectual property rights of others. If you believe
					that any material available on or through the Services infringes upon
					any copyright you own or control, please immediately refer to the
					&lsquo;COPYRIGHT INFRINGEMENTS&rsquo; section below.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>3. USER REPRESENTATIONS</b>
				</span>
			</p>

			<p>
				<span>
					By using the Services, you represent and warrant that: (1) all
					registration information you submit will be true, accurate, current,
					and complete; (2) you will maintain the accuracy of such information
					and promptly update such registration information as necessary; (3)
					you have the legal capacity and you agree to comply with these Legal
					Terms; (4) you are not a minor in the Jurisdiction in which you
					reside, or if a minor, you have received parental permission to use
					the Services; (5) you will not access the Services through automated
					or non-human means, whether through a bot, script, or otherwise; (6)
					you will not use the Services for any illegal or unauthorized purpose;
					and (7) your use of the Services will not violate any applicable law
					or regulation.
				</span>
			</p>
			<p>
				<span>
					If you provide any information that is untrue, inaccurate, not
					current, or incomplete, we have the right to suspend or terminate your
					account and refuse any current or future use of the Services (or any
					portion thereof).
				</span>
			</p>
			<p>
				<span>
					<b>4. USER REGISTRATION</b>
				</span>
			</p>

			<p>
				<span>
					You may be required to register to use the Services. You agree to keep
					your password confidential and will be responsible for all use of your
					account and password. We reserve the right to remove, reclaim, or
					change a username you select if we determine, in our sole discretion,
					that such username is inappropriate, obscene, or otherwise
					objectionable.
				</span>
			</p>
			<p>
				<span>
					<b>5. PRODUCTS</b>
				</span>
			</p>
			<p>
				<span>
					We make every effort to display as accurately as possible the colors,
					features, specifications, and details of the products available on the
					Services. However, we do not guarantee that the colors, features,
					specifications, and details of the products will be accurate,
					complete, reliable, current, or free of other errors, and your
					electronic display may not accurately reflect the actual colors and
					details of the products. All products are subject to availability, and
					we cannot guarantee that items will be in stock. We reserve the right
					to discontinue any products at any time for any reason. Prices for all
					products are subject to change.
				</span>
			</p>
			<p>
				<span>
					<b>6. PURCHASES AND PAYMENT</b>
				</span>
			</p>
			<p>
				<span>We accept the following forms of payment:</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>- Visa</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>- Mastercard</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>- American Express</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>- Discover</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>- PayPal</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					You agree to provide current, complete, and accurate purchase and
					account information for all purchases made via the Services. You
					further agree to promptly update account and payment information,
					including email address, payment method, and payment card expiration
					date, so that we can complete your transactions and contact you as
					needed. Sales tax will be added to the price of purchases as deemed
					required by us. We may change prices at any time. All payments shall
					be in Indian Rupees. You agree to pay all charges at the prices then
					in effect for your purchases and any applicable shipping fees, and you
					authorize us to charge your chosen payment provider for any such
					amounts upon placing your order. We reserve the right to correct any
					errors or mistakes in pricing, even if we have already requested or
					received payment. We reserve the right to refuse any order placed
					through the Services. In our sole discretion, we may limit or cancel
					quantities purchased per person, household, or order. These
				</span>
			</p>
			<p>
				<span>
					restrictions may include orders placed by or under the same customer
					account, the same payment method, and/or orders that use the same
					billing or shipping address. We reserve the right to limit or prohibit
					orders that, in our sole judgment, appear to be placed by dealers,
					resellers, or distributors.
				</span>
			</p>

			<p>
				<span>
					<b>7. RETURN</b>
				</span>
			</p>
			<p>
				<span>
					If you find that product is faullt and wish to return, please submit
					the product opening video throught Whatsapp to company at phone
					number: +919811183052. After confirmation, company will send assitance
					to collect the product from customer's destination address.
				</span>
			</p>
			<p>
				<span>For refunds, please refer to our refund policy.</span>
			</p>
			<p>
				<span>
					<b>8. PROHIBITED ACTIVITIES</b>
				</span>
			</p>

			<p>
				<span>
					You may not access or use the Services for any purpose other than that
					for which we make the Services available. The Services may not be used
					in connection with any commercial endeavors except those that are
					specifically endorsed or approved by us.
				</span>
			</p>
			<p>
				<span>As a user of the Services, you agree not to:</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Systematically retrieve data or other content from the
					Services to create or compile, directly or indirectly, a collection,
					compilation, database, or directory without written permission from
					us.
				</span>
			</p>

			<p>
				<span>
					* Trick, defraud, or mislead us and other users, especially in any
					attempt to learn sensitive account information such as user passwords.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					* Circumvent, disable, or otherwise interfere with security-related
					features of the Services, including features that prevent or restrict
					the use or copying of any Content or enforce limitations on the use of
					the Services and/or the Content contained therein. Disparage, tarnish,
					or otherwise harm, in our opinion, us and/or the Services. Use any
					information obtained from the Services to harass, abuse, or harm
					another person. Make improper use of our support services or submit
					false reports of abuse or misconduct.
				</span>
			</p>

			<p>
				<span>
					&raquo; Use the Services in a manner inconsistent with any applicable
					laws or regulations.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Engage in unauthorized framing of or linking to the Services.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Upload or transmit (or attempt to upload or to transmit)
					viruses, Trojan horses, or other material, including excessive use of
					capital letters and spamming (continuous posting of repetitive text),
					that interferes with any party&#39;s uninterrupted use and enjoyment
					of the Services or modifies, impairs, disrupts, alters, or interferes
					with the use, features, functions, operation, or maintenance of the
					Services.
				</span>
			</p>

			<p>
				<span>
					&raquo; Engage in any automated use of the system, such as using
					scripts to send comments or messages, or using any data mining,
					robots, or similar data gathering and extraction tools.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					* Delete the copyright or other proprietary rights notice from any
					Content.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Attempt to impersonate another user or person or use the
					username of another user.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Upload or transmit (or attempt to upload or to transmit) any
					material that acts as a passive or active information collection or
					transmission mechanism, including without limitation,
				</span>
			</p>
			<p>
				<span>
					clear graphics interchange formats (&lsquo;gifs&rsquo;), 1x1 pixels,
					web bugs, cookies, or other similar devices (sometimes referred to as
					&lsquo;spyware&rsquo; or &lsquo;passive collection mechanisms&rsquo;
					or &lsquo;pems&rsquo;).
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Interfere with, disrupt, or create an undue burden on the
					Services or the networks or services connected to the Services.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Harass, annoy, intimidate, or threaten any of our employees or
					agents engaged in providing any portion of the Services to you.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Attempt to bypass any measures of the Services designed to
					prevent or restrict access to the Services, or any portion of the
					Services.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Copy or adapt the Services&rsquo; software, including but not
					limited to Flash, PHP, HTML, JavaScript, or other code.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Except as permitted by applicable law, decipher, decompile,
					disassemble, or reverse engineer any of the software comprising or in
					any way making up a part of the Services.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Except as may be the result of standard search engine or
					Internet browser usage, use, launch, develop, or distribute any
					automated system, including without limitation, any spider, robot,
					cheat utility, scraper, or offline reader that accesses the Services,
					or uses or launches any unauthorized script or other software.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Use a buying agent or purchasing agent to make purchases on
					the Services.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Make any unauthorized use of the Services, including
					collecting usernames and/or email addresses of users by electronic or
					other means to send unsolicited email, or creating user accounts by
					automated means or under false pretenses.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Use the Services as part of any effort to compete with us or
					otherwise use the Services and/or the Content for any
					revenue-generating endeavor or commercial enterprise.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>9. USER GENERATED CONTRIBUTIONS</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					The Services may invite you to chat, contribute to, or participate in
					blogs, message boards, online forums, and other functionality, and may
					provide you with the opportunity to create, submit, post, display,
					transmit, perform, publish, distribute, or broadcast content and
					materials to us or on the Services, including but not limited to text,
					writings, video, audio, photographs, graphics, comments, suggestions,
					or personal information or other material (collectively,
					&lsquo;Contributions&rsquo;). Contributions may be viewable by other
					users of the Services and through third-party websites. As such, any
					Contributions you transmit may be treated as non-confidential and
					non-proprietary. When you create or make available any Contributions,
					you thereby represent and
				</span>
			</p>
			<p>
				<span>warrant that:</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					* The creation, distribution, transmission, public display, or
					performance, and the accessing, downloading, or copying of your
					Contributions do not and will not infringe the proprietary rights,
					including but not limited to the copyright, patent, trademark, trade
					secret, or moral rights of any third party.
				</span>
			</p>

			<p>
				<span>
					&raquo; You are the creator and owner of or have the necessary
					licenses, rights, consents, releases, and permissions to use and to
					authorize us, the Services, and other users of the Services to use
					your Contributions in any manner contemplated by the Services and
					these Legal Terms.
				</span>
			</p>

			<p>
				<span>
					&raquo; You have the written consent, release, and/or permission of
					every identifiable person in your Contributions to use the name or
					likeness of every such identifiable person to enable inclusion and use
					of your Contributions in any manner contemplated by the Services and
					these Legal Terms.
				</span>
			</p>
			<p>
				<span>
					&raquo; Your Contributions are not false, inaccurate, or misleading.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					* Your Contributions are not unsolicited or unauthorized advertising,
					promotional materials, pyramid schemes, chain letters, spam, mass
					mailings, or other forms of solicitation.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Your Contributions are not obscene, lewd, lascivious, filthy,
					violent, harassing, libelous, slanderous, or otherwise objectionable
					(as determined by us).
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Your Contributions do not ridicule, mock, disparage,
					intimidate, or abuse anyone.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Your Contributions are not used to harass or threaten (in the
					legal sense of those terms) any other person and to promote violence
					against a specific person or class of people.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					* Your Contributions do not violate any applicable law, regulation, or
					rule.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Your Contributions do not violate the privacy or publicity
					rights of any third party.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Your Contributions do not violate any applicable law
					concerning child pornography, or otherwise intended to protect the
					health or well-being of minors.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					* Your Contributions do not include any offensive comments that are
					connected to race, national origin, gender, sexual preference, or
					physical handicap.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					&raquo; Your Contributions do not otherwise violate, or link to
					material that violates, any provision of these Legal Terms, or any
					applicable law or regulation.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					Any use of the Services in violation of the foregoing violates these
					Legal Terms and may result in, among other things, termination or
					suspension of your rights to use the Services.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>10. CONTRIBUTION LICENCE</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					By posting your Contributions to any part of the Services or making
					Contributions accessible to the Services by linking your account from
					the Services to any of your social networking accounts, you
					automatically grant, and you represent and warrant that you have the
					right to grant, to us an unrestricted, unlimited, irrevocable,
					perpetual, non-exclusive, transferable, royalty-free, fully paid,
					worldwide right, and license to host, use, copy, reproduce, disclose,
					sell, resell, publish, broadcast, retitle, archive, store, cache,
					publicly perform, publicly display, reformat, translate, transmit,
					excerpt (in whole or in part), and distribute such Contributions
					(including, without limitation, your image and voice) for any purpose,
					commercial, advertising, or otherwise, and to prepare derivative works
					of, or incorporate into other works, such as Contributions, and grant
					and authorize sublicenses of the foregoing. The use and distribution
					may occur in any media format and through any media channels.
				</span>
			</p>
			<p>
				<span>
					This license will apply to any form, media, or technology now known or
					hereafter developed, and includes our use of your name, company name,
					and franchise name, as applicable, and any of the trademarks, service
					marks, trade names, logos, and personal and commercial images you
					provide. You waive all moral rights in your Contributions, and you
					warrant that moral rights have not otherwise been asserted in your
					Contributions We do not assert any ownership over your Contributions.
					You retain full ownership of all of your Contributions and any
					intellectual property rights or other proprietary rights associated
					with your Contributions. We are not liable for any statements or
					representations in your Contributions provided by you in any area of
					the Services. You are solely responsible for your
				</span>
			</p>
			<p>
				<span>
					Contributions to the Services and you expressly agree to exonerate us
					from any responsibility and to refrain from any legal action against
					us regarding your Contributions. We have the right, in our sole and
					absolute discretion, (1) to edit, redact, or otherwise change any
					Contributions: (2) to re-categorise any Contributions to place them in
					more appropriate locations on the Services; and (3) to pre-screen or
					delete any Contributions at any time and for any reason, without
					notice. We have no obligation to monitor your Contributions.
				</span>
			</p>

			<p>
				<span>
					<b>11. GUIDELINES FOR REVIEWS</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					We may provide you with areas on the Services to leave reviews or
					ratings. When posting a review, you must comply with the following
					criteria: (1) you should have firsthand experience with the
					person/entity being reviewed; (2) your reviews should not contain
					offensive profanity or abusive, racist, offensive, or hateful
					language; (3) your reviews should not contain discriminatory
					references based on religion, race, gender, national origin, age,
					marital status, sexual orientation, or disability; (4) your reviews
					should not contain references to illegal activity; (5) you should not
					be affiliated with competitors if posting negative reviews; (6) you
					should not make any conclusions as to the legality of conduct; (7) you
					may not post any false or misleading statements; and (8) you may not
					organize a campaign encouraging others to post reviews, whether
					positive or negative. We may accept, reject, or remove reviews at our
					sole discretion. We have absolutely no obligation to screen reviews or
					to delete reviews, even if anyone considers reviews objectionable or
					inaccurate. Reviews are not endorsed by us and do not necessarily
					represent our opinions or the views of any of our affiliates or
					partners. We do not assume liability for any review or any claims,
					liabilities, or losses resulting from any review. By posting a review,
					you hereby grant to us a perpetual, non-exclusive, worldwide,
					royalty-free, fully paid, assignable, and sublicensable right and
					license to reproduce, modify, translate, transmit by any means,
					display, perform, and/or distribute all content relating to the
					review.
				</span>
			</p>

			<p>
				<span>
					<b>12. SOCIAL MEDIA</b>
				</span>
			</p>

			<p>
				<span>
					As part of the functionality of the Services, you may link your
					account with online accounts you have with third-party service
					providers (each such account, a &lsquo;Third-Party Account&rsquo;) by
					either:
				</span>
			</p>
			<p>
				<span>
					(1) providing your Third-Party Account login information through the
					Services: or (2) allowing us to access your Third-Party Account, as is
					permitted under the applicable terms and conditions that govern your
					use of each Third-Party Account. You represent and warrant that you
					are entitled to disclose your Third-Party Account login information to
					us and/or grant us access to your Third-Party Account, without breach
					by you of any of the terms and conditions that govern your use of the
					applicable Third-Party Account, and without obligating us to pay any
					fees or making us subject to any usage limitations imposed by the
					third-party service provider of the Third-Party Account. By granting
					us access to any Third-Party Accounts, you understand that (1) we may
					access, make available, and store (if applicable) any content that you
					have provided to and stored in your Third-Party Account (the
					&lsquo;Social Network Content&rsquo;) so that it is available on and
					through the Services via your account, including without limitation
					any friend lists and (2) we may submit to and receive from your
					Third-Party Account additional Information to the extent you are
					notified when you link your account with the | third-party Account.
					Depending on the | third-party Accounts you choose and subject to the
					privacy settings that you have set in such Third-Party Accounts,
					personally identifiable information that you post to your Third-Party
					Accounts may be available on and through your account on the Services.
					Please note that if a Third-Party Account or associated service
					becomes unavailable or our access to such Third-Party Account is
					terminated by the third-party service provider, then Social Network
					Content may no longer be available on and through the Services. You
					will have the ability to disable the connection between your account
					on the Services and your Third-Party Accounts at any time. PLEASE NOTE
					THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS
					ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
					AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no
					effort to review any Social Network Content for any purpose, including
					but not limited to, accuracy, legality, or non-infringement, and we
					are not responsible for any Social Network Content. You acknowledge
					and agree that we may access your email address book associated with a
					Third-Party Account and your contacts list stored on your mobile
					device or tablet computer solely for purposes of identifying and
					informing you of those contacts who have also registered to use the
					Services. You can deactivate the connection between the Services and
					your Third-Party Account by contacting us using the contact
					information below or through your account settings (if applicable). We
					will attempt to delete any information stored on our servers that was
					obtained through such a Third-Party Account, except the username and
					profile picture that become associated with your account.
				</span>
			</p>

			<p>
				<span>
					<b>13. SERVICES MANAGEMENT</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					We reserve the right, but not the obligation, to (1) monitor the
					Services for violations of these Legal Terms; (2) take appropriate
					legal action against anyone who, in our sole discretion, violates the
					law or these Legal Terms, including without limitation, reporting such
					user to law enforcement authorities; (3) in our sole discretion and
					without limitation, refuse, restrict access to, limit the availability
					of, or disable (to the extent technologically feasible) any of your
					Contributions or any portion thereof, (4) in our sole discretion and
					without limitation, notice, or liability, to remove from the Services
					or otherwise disable all files and content that are excessive in size
					or are in any way burdensome to our manner designed to protect our
					rights and property and to facilitate the proper functioning of the
					Services.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>14. PRIVACY POLICY</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					We care about data privacy and security. By using the Services, you
					agree to be bound by our Privacy Policy posted on the Services, which
					is incorporated into these Legal Terms. Please be advised the Services
					are hosted in the United States. If you access the Services from any
					other region of the world with laws or other requirements governing
					personal data collection, use, or disclosure that differ from
					applicable laws in the United States, then through your continued use
					of the Services, you are transferring your data to the United States,
					and you expressly consent to have your data transferred to and
					processed in the United States.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>15. COPYRIGHT INFRINGEMENTS</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					We respect the intellectual property rights of others. If you believe
					that any material available on or through the Services infringes upon
					any copyright you own or control, please immediately notify us using
					the contact information provided below (a &lsquo;Notification&#39;). A
					copy of your Notification will be sent to the person who posted or
					stored the material addressed in the Notification. Please be advised
					that under applicable law you may be held liable for damages if you
					make material misrepresentations in a Notification. Thus, if you are
					not sure that material located on or linked to by the Services
					infringes your copyright, you should consider first contacting an
					attorney.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>16. TERM AND TERMINATION</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					These Legal Terms shall remain in full force and effect while you use
					the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
					TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
					NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
					BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR NO
					REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
					WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
					APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
					PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT
					OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
					SOLE DISCRETION.
				</span>
			</p>

			<p>
				<span>
					If we terminate or suspend your account for any reason, you are
					prohibited from registering and creating a new account under your
					name, a fake or borrowed name, or the name of any third party, even if
					you may be acting on behalf of the third party. In addition to
					terminating or suspending your account, we reserve the right to take
					appropriate legal action, including without limitation pursuing civil,
					criminal, and injunctive redress.
				</span>
			</p>

			<p>
				<span>
					<b>17. MODIFICATIONS AND INTERRUPTIONS</b>
				</span>
			</p>

			<p>
				<span>
					We reserve the right to change, modify, or remove the contents of the
					Services at any time or for any reason at our sole discretion without
					notice. However, we have no obligation to update any information on
					our Services. We also reserve the right to modify or discontinue all
					or part of the Services without notice at any time. modification,
					price change, suspension, or discontinuance of the Services.
				</span>
			</p>

			<p>
				<span>
					We cannot guarantee the Services will be available at all times. We
					may experience hardware, software, or other problems or need to
					perform maintenance related to the Services, resulting in
					interruptions, delays, or errors. We reserve the right to change,
					revise, update, suspend, discontinue, or otherwise modify the Services
					at any time or for any reason without notice to you.
				</span>
			</p>
			<p>
				<span>
					You agree that we have no liability whatsoever for any loss, damage,
					or inconvenience caused by your inability to access or use the
					Services during any downtime or discontinuance of the Services.
					Nothing in these Legal Terms will be construed to obligate us to
					maintain and support the Services or to supply any corrections,
					updates, or releases in connection therewith.
				</span>
			</p>

			<p>
				<span>
					<b>18. GOVERNING LAW</b>
				</span>
			</p>

			<p>
				<span>
					These Legal Terms shall be governed by and defined following the laws
					of India. Combined Logics and you irrevocably consent that the courts
					of India shall have exclusive jurisdiction to resolve any dispute
					which may arise in connection with these Legal Terms.
				</span>
			</p>

			<p>
				<span>
					<b>19. DISPUTE RESOLUTION</b>
				</span>
			</p>

			<p>
				<span>Informal Negotiations</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					To expedite resolution and control the cost of any dispute,
					controversy, or claim related to these Legal Terms (each a
					&lsquo;Dispute&rsquo; and collectively, the &lsquo;Disputes&#39;)
					brought by either you or us (individually, a &lsquo;Party&rsquo; and
					collectively, the &#39;Parties&rsquo;), the Parties agree to first
					attempt to negotiate any Dispute (except those Disputes expressly
					provided below) informally for at least thirty (30) days before
					initiating arbitration. Such informal negotiations commence upon
					written notice from one Party to the other Party.
				</span>
			</p>

			<p>
				<span>Binding Arbitration</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					Any dispute arising out of or in connection with these Legal Terms,
					including any question regarding its existence, validity, or
					termination, shall be referred to and finally resolved by the
					International Commercial Arbitration Court under the European
					Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according
					to the Rules of this ICAC, which, as a result of referring to it, is
					considered as part of this clause. The number of arbitrators shall be
					three (3). The seat, or legal place, of arbitration, shall be New
					Delhi, India. The language of the proceedings shall be Hindi. The
					governing law of these Legal Terms shall be the substantive law of
					India.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>Restrictions</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					The Parties agree that any arbitration shall be limited to the Dispute
					between the Parties individually. To the full extent permitted by law,
					(a) no arbitration shall be joined with any other proceeding: (b)
					there is no right or authority for any Dispute to be arbitrated on a
					class-action basis or to utilize class-action procedures, and (c)
					there is no right or authority for any Dispute to be brought in a
					purported representative capacity on behalf of the general public or
					any other persons.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>Exceptions to Informal Negotiations and Arbitration</span>
			</p>

			<p>
				<span>
					The Parties agree that the following Disputes are not subject to the
					above provisions concerning informal negotiations binding arbitration:
					(a) any Disputes seeking to enforce or protect, or concerning the
					validity of, any of the intellectual property rights of a Party; (b)
					any Dispute related to, or arising from, allegations of theft, piracy,
					invasion of privacy, or unauthorized use; and
				</span>
			</p>
			<p>
				<span>
					(c) any claim for injunctive relief. If this provision is found to be
					illegal or unenforceable, then neither Party will elect to arbitrate
					any Dispute falling within that portion of this provision found to be
					illegal or unenforceable and such Dispute shall be decided by a court
					of competent jurisdiction within the courts listed for jurisdiction
					above, and the Parties agree to submit to the personal jurisdiction of
					that court.
				</span>
			</p>

			<p>
				<span>
					<b>20. CORRECTIONS</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					There may be information on the Services that contains typographical
					errors, inaccuracies, or omissions, including descriptions, pricing,
					availability, and various other information. We reserve the right to
					correct any errors, inaccuracies, or omissions and to change or update
					the information on the Services at any time, without prior notice.
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>21. DISCLAIMER</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
					AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
					FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
					OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
					INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
					MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
					NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
					ACCURACY OR COMPLETENESS OF THE SERVICES&rsquo; CONTENT OR THE CONTENT
					OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
					WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
					MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
					INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
					YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO
					OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
					AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
					CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
					VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR
					THROUGH THE SERVICES OF ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
					OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
					ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
					TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
					WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
					OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
					SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
					APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
					NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
					TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
					SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
					MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND
					EXERCISE CAUTION WHERE APPROPRIATE.
				</span>
			</p>

			<p>
				<span>
					<b>22. LIMITATIONS OF LIABILITY</b>
				</span>
			</p>

			<p>
				<span>
					IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
					TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
					EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
					PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
					USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
					OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
					HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
					OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
					PAID, IF ANY, BY YOU TO US DURING THE (1) MONTH PERIOD BEFORE ANY
					CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS
					DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
					LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL
					OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU
					MAY HAVE ADDITIONAL RIGHTS.
				</span>
			</p>

			<p>
				<span>
					<b>23. INDEMNIFICATION</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					You agree to defend, indemnify, and hold us harmless, including our
					subsidiaries, affiliates, and all of our respective officers, agents,
					partners, and employees, from and against any loss, damage, liability,
					claim, or demand, including reasonable attorneys fees and expenses,
					made by any third party due to or arising out of (1) your
					Contributions: (2) use of the Services; (3) breach of these Legal
					Terms; (4) any breach of your representations and warranties outlined
					in these Legal Terms; (5) your violation of the rights of a third
					party, including but not limited to intellectual property rights; or
					(6) any overt harmful act toward any other user of the Services with
					whom you connected via the Services. Notwithstanding the foregoing, we
					reserve the right, at your expense, to assume the exclusive defense
					and control of any matter for which you are required to indemnify us,
					and you agree to cooperate, at your expense, with our defense of such
					claims. We will use reasonable efforts to notify you of any such
					claim, action, or proceeding that is subject to this indemnification
					upon becoming aware of it.
				</span>
			</p>

			<p>
				<span>
					<b>24. USER DATA</b>
				</span>
			</p>

			<p>
				<span>
					We will maintain certain data that you transmit to the Services to
					manage the performance of the Services, as well as data relating to
					your use of the Services Although we perform routine backups of data,
					you are solely responsible for all data that you transmit or that
					relates to any activity you have undertaken using the Services. You
					agree that we shall have no liability to you for any loss or
					corruption of any such data, and you hereby waive any right of action
					against us arising from any such loss or corruption of such data.
				</span>
			</p>

			<p>
				<span>
					<b>25. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</b>
				</span>
			</p>

			<p>
				<span>
					Visiting the Services, sending us emails, and completing online forms
					constitute electronic communications. You consent to receive
					electronic communications, and you agree that all agreements, notices,
					disclosures, and other communications we provide to you
					electronically, via email, and on the Services, satisfy any legal
					requirement that such communication be in writing. YOU HEREBY AGREE TO
					THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
					RECORDS, AND ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
					TRANSACTIONS INITIATED OR COMPLETED BY THE US OR VIA THE SERVICES. You
					hereby waive any rights or requirements under any statutes,
					regulations, rules, ordinances, or other laws in any jurisdiction that
					require an original signature or delivery or retention of
					non-electronic records, or to payments or the granting of credits by
					any means other than electronic means.
				</span>
			</p>
			<p>
				<span>
					<b>26. MISCELLANEOUS</b>
				</span>
			</p>

			<p>
				<span>
					These Legal Terms and any policies or operating rules posted by us on
					the Services or concerning the Services constitute the entire
					agreement and understanding between you and us. Our failure to
					exercise or enforce any right or provision of these Legal Terms shall
					not operate as a waiver of such right or provision. These Legal Terms
					operate to the fullest extent permissible by law. We may assign any or
					all of our rights and obligations to others at any time. We shall not
					be responsible or liable for any loss, damage, delay, or failure to
					act caused by any cause beyond our reasonable control. If any
					provision or part of a provision of these Legal Terms is determined to
					be unlawful, void, or unenforceable, that provision or part of the
					provision is deemed severable from these Legal Terms and does not
					affect the validity and enforceability of any remaining provisions.
					There is no joint venture, partnership, employment, or agency
					relationship created between you and us as a result of these Legal
					Terms or use of the Services. You agree that these Legal Terms will
					not be construed against us by having drafted them. You hereby waive
					any defenses you may have based on the electronic form of these Legal
					Terms and the lack of signing by the parties hereto to execute these
					Legal Terms.
				</span>
			</p>

			<p>
				<span>
					<b>27. REFUND POLICY</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					No refund will be provided if the user does not submit the request
					within one week. To report a broken or unacceptable product, the user
					should make a video on opening the product. Without proper video, the
					company has all the rights to reject the claim.
					<p>
						<span>
							Once confirmed that the product is faulty, then the company will
							refund the amount in the customer's bank account within 7 days.
						</span>
					</p>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>28. SHIPPING POLICY</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					After the order date, orders will be sent and delivered to the
					customer within seven working days.
				</span>
			</p>
			<p>
				<span>
					<u>Shipping costs</u>: The distance and the region will determine the
					shipping cost. The shipping charge will be automatically calculated
					after the customer provides the delivery address.
				</span>
			</p>

			<p>
				<span>
					<u>Potential service interruptions</u>: In case of natural disasters
					or variables outside of company's control, orders may take longer to
					arrive. However, company will inform customers about the reason for
					delay and will try to deliver product as soon as possible.
				</span>
			</p>

			<p>
				<span></span>
			</p>
			<p>
				<span>
					<b>28. CONTACT US</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>
					To resolve a complaint regarding the Services or to receive further
					information regarding the use of the Services, please contact us at:
				</span>
			</p>
			<p>
				<span>
					<b>Combined Logics</b>
				</span>
			</p>
			<p>
				<span></span>
			</p>
			<p>
				<span>C-84, Janki Puri, Near Chanakya Puri, Uttam Nagar</span>
			</p>

			<p>
				<span>West Delhi, New Delhi 110059, India</span>
			</p>

			<p>
				<span>Phone (WhatsApp): +919811183052</span>
			</p>

			<p>
				<span>sow.trendy@gmail.com</span>
			</p>
		</Container>
	);
};

export default TermsConditions;
