import React, { useEffect, useState } from 'react';
import banner1 from '../banners/Banner-1.jpg';
import banner2 from '../banners/Banner-2.jpg';
import banner3 from '../banners/Banner-3.jpg';

const ProductCarousel = () => {
	const images = [banner1, banner2, banner3];
	const [currentImage, setCurrentImage] = useState(banner1);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentImage(images[Math.floor(Math.random() * images.length)]);
		}, 10000);

		return () => clearInterval(intervalId);
	});

	return <img alt='sow trendy banner' src={currentImage} />;
};

export default ProductCarousel;
