import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import Product from '../components/Product';
import Message from '../components/Message';
import Loader from '../components/Loader';

const CategoryScreen = () => {
	const { category } = useParams(); // Fetch category name from URL
	const formattedCategory = category ? category.replace(/-/g, ' ') : ''; // Replace hyphens with spaces
	const [categoryIDs, setCategoryIDs] = useState([]);
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchCategoryIDs = async () => {
			try {
				const { data } = await axios.get(`/api/category/${formattedCategory}`);
				setCategoryIDs(data.categoryIDs);
			} catch (error) {
				setError('Error fetching category IDs');
				setLoading(false);
			}
		};

		if (formattedCategory) {
			fetchCategoryIDs();
		}
	}, [formattedCategory]);

	useEffect(() => {
		const fetchProducts = async () => {
			if (categoryIDs.length > 0) {
				try {
					const { data } = await axios.get(`/api/products/category`, {
						params: { categoryIDs: categoryIDs.join(',') },
					});
					setProducts(data.products);
					setLoading(false);
				} catch (error) {
					setError('Error fetching products');
					setLoading(false);
				}
			}
		};

		fetchProducts();
	}, [categoryIDs]);

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>{error}</div>;
	}

	return (
		<>
			<Breadcrumb>
				<Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
					Home
				</Breadcrumb.Item>
				<Breadcrumb.Item active>{formattedCategory}</Breadcrumb.Item>
			</Breadcrumb>
			<h2>{formattedCategory}</h2>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Row>
					{products &&
						products.map((product) => (
							<Col key={product._id} sm={12} ms={6} lg={4} xl={3}>
								<Product product={product} />
							</Col>
						))}
				</Row>
			)}
		</>
	);
};

export default CategoryScreen;
