import axios from 'axios';
import {
	PRODUCT_DISCOUNT_UPDATE_REQUEST,
	PRODUCT_DISCOUNT_UPDATE_SUCCESS,
	PRODUCT_DISCOUNT_UPDATE_FAIL,
	DISCOUNT_FETCH_REQUEST,
	DISCOUNT_FETCH_SUCCESS,
	DISCOUNT_FETCH_FAIL,
} from '../constants/discountConstants';

// Action to update product discount details
export const createorupdateProductDiscount =
	(discount) => async (dispatch, getState) => {
		try {
			dispatch({ type: PRODUCT_DISCOUNT_UPDATE_REQUEST });

			const {
				userLogin: { userInfo },
			} = getState();

			const config = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			};

			const { data } = await axios.put(
				`/api/product-discounts/${discount.product_id}`,
				discount,
				config
			);

			dispatch({
				type: PRODUCT_DISCOUNT_UPDATE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: PRODUCT_DISCOUNT_UPDATE_FAIL,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			});
		}
	};

export const fetchDiscounts = () => async (dispatch) => {
	try {
		dispatch({ type: DISCOUNT_FETCH_REQUEST });
		const { data } = await axios.get('/api/product-discounts');
		dispatch({ type: DISCOUNT_FETCH_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: DISCOUNT_FETCH_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
