import axios from 'axios';

// Function to fetch all discounts
export const fetchAllDiscounts = async () => {
	try {
		const response = await axios.get('/api/product-discounts');
		return response.data; // Assuming the API returns an array of discounts
	} catch (error) {
		console.error('Error fetching discounts:', error);
		return [];
	}
};

export const calculateDiscountedPrice = (
	productId,
	originalPrice,
	discountsArray
) => {
	try {
		// Ensure discountsArray is an array
		if (!Array.isArray(discountsArray)) {
			throw new Error('Discounts should be an array');
		}

		// Find the discount for the given productId in the discountsArray
		const discount = discountsArray.find((d) => d.product_id === productId);

		if (discount && discount.activate) {
			let discountedPrice = originalPrice;

			if (discount.discount_unit === 'amount') {
				discountedPrice -= discount.discount_value;
			} else if (discount.discount_unit === 'percentage') {
				discountedPrice -= (originalPrice * discount.discount_value) / 100;
			}

			// Ensure the discounted price is less than the original price
			if (discountedPrice < originalPrice) {
				return discountedPrice;
			}
		}

		return originalPrice;
	} catch (error) {
		// Handle other errors
		console.error('Error calculating discount:', error);
		return originalPrice;
	}
};
