// PlaceOrderScreen.js
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap';
import Message from '../components/Message';
import { NumericFormat } from 'react-number-format';
import { currencies } from '../components/Currencies';
import { createOrder } from '../actions/orderActions';
import { calculateDiscountedPrice } from '../Helpers/calculateDiscountedPrice';

const PlaceOrderScreen = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const cart = useSelector((state) => state.cart);
	const userCurrency = useSelector((state) => state.userCurrency);
	const { conversionRates } = userCurrency;
	const user_currency = conversionRates ? Object.keys(conversionRates)[1] : '';

	const discounts = useSelector(
		(state) => state.productDiscountsList.discounts
	);

	const [error, setError] = useState(null); // Define the error state

	// Calculate discounted prices for cart items using helper function
	const discountedPrices = useMemo(() => {
		return cart.cartItems.reduce((acc, item) => {
			const discountedPrice = calculateDiscountedPrice(
				item.product,
				item.price,
				discounts
			);
			acc[item.product] = discountedPrice > 0 ? discountedPrice : 0;
			return acc;
		}, {});
	}, [cart.cartItems, discounts]);

	const addDecimals = (num) => {
		return (Math.round(num * 100) / 100).toFixed(2);
	};

	// Calculate original items price without discount
	const originalItemsPrice = useMemo(() => {
		return addDecimals(
			cart.cartItems.reduce((acc, item) => acc + item.qty * item.price, 0)
		);
	}, [cart.cartItems]);

	// Calculate discounted items price
	const itemsPrice = useMemo(() => {
		return addDecimals(
			cart.cartItems.reduce(
				(acc, item) =>
					acc +
					item.qty *
						(user_currency &&
						item.currency &&
						item.currency.toLowerCase() !== user_currency
							? (
									(discountedPrices[item.product] || item.price) /
									(conversionRates[user_currency]?.[
										item.currency.toLowerCase()
									] || 1)
							  ).toFixed(2)
							: (discountedPrices[item.product] || item.price).toFixed(2)),
				0
			)
		);
	}, [cart.cartItems, conversionRates, user_currency, discountedPrices]);

	const calculateShippingPrice = (itemsPrice) => {
		const baseShipping = 100;
		const additionalShipping = Math.floor(itemsPrice / 1000) * 100;
		return baseShipping + additionalShipping;
	};

	const shippingPrice = addDecimals(calculateShippingPrice(itemsPrice));
	const taxPrice = addDecimals(Number((0.15 * itemsPrice).toFixed(2)));
	const totalPrice = (
		Number(itemsPrice) +
		Number(shippingPrice) +
		Number(taxPrice)
	).toFixed(2);

	const placeOrderHandler = useCallback(async () => {
		console.log('order items', cart.cartItems);
		const createdOrder = await dispatch(
			createOrder({
				orderItems: cart.cartItems,
				shippingAddress: cart.shippingAddress,
				paymentMethod: cart.paymentMethod,
				itemsPrice,
				shippingPrice,
				taxPrice,
				totalPrice,
				orderCurrency: user_currency,
			})
		);
		// console.log('order', createdOrder);
		navigate(`/order/${createdOrder._id}`);
	}, [
		dispatch,
		cart,
		itemsPrice,
		shippingPrice,
		taxPrice,
		totalPrice,
		user_currency,
		navigate,
	]);

	return (
		<Row>
			<Col md={8}>
				<ListGroup variant='flush'>
					<ListGroup.Item>
						<h2>Shipping</h2>
						<p>
							<strong>Address:</strong>
							{cart.shippingAddress.address}, {cart.shippingAddress.city}{' '}
							{cart.shippingAddress.postalCode}, {cart.shippingAddress.country}
						</p>
					</ListGroup.Item>

					<ListGroup.Item>
						<h2>Payment Method</h2>
						<strong>Method: </strong>
						{cart.paymentMethod}
					</ListGroup.Item>

					<ListGroup.Item>
						<h2>Order Items</h2>
						{cart.cartItems.length === 0 ? (
							<Message>Your cart is empty</Message>
						) : (
							<ListGroup variant='flush'>
								{cart.cartItems.map((item, index) => {
									const discountedPrice =
										discountedPrices[item.product] || item.price;

									return (
										<ListGroup.Item key={index}>
											<Row>
												<Col md={1}>
													<Image
														src={item.image[0]}
														alt={item.name}
														fluid
														rounded
													/>
												</Col>
												<Col>
													<Link to={`/product/${item.product}`}>
														{item.name}
													</Link>
												</Col>
												<Col md={4}>
													{item.qty} x{' '}
													{user_currency &&
													item.currency &&
													item.currency.toLowerCase() !== user_currency ? (
														<>
															{item.price !== discountedPrice &&
																discountedPrice < item.price && (
																	<>
																		<NumericFormat
																			value={(
																				item.price /
																				(conversionRates[user_currency]?.[
																					item.currency.toLowerCase()
																				] || 1)
																			).toFixed(2)}
																			displayType={'text'}
																			thousandSeparator={true}
																			prefix={
																				currencies[user_currency.toUpperCase()]
																					.symbol
																			}
																			className='text-muted text-decoration-line-through'
																		/>
																		&nbsp;{' '}
																		<NumericFormat
																			value={(
																				discountedPrice /
																				(conversionRates[user_currency]?.[
																					item.currency.toLowerCase()
																				] || 1)
																			).toFixed(2)}
																			displayType={'text'}
																			thousandSeparator={true}
																			prefix={
																				currencies[user_currency.toUpperCase()]
																					.symbol
																			}
																			className='text-danger'
																		/>
																	</>
																)}
															{item.price === discountedPrice && (
																<NumericFormat
																	value={(
																		item.price /
																		(conversionRates[user_currency]?.[
																			item.currency.toLowerCase()
																		] || 1)
																	).toFixed(2)}
																	displayType={'text'}
																	thousandSeparator={true}
																	prefix={
																		currencies[user_currency.toUpperCase()]
																			.symbol
																	}
																/>
															)}
														</>
													) : (
														<>
															{item.price !== discountedPrice &&
																discountedPrice < item.price && (
																	<>
																		<NumericFormat
																			value={item.price.toFixed(2)}
																			displayType={'text'}
																			thousandSeparator={true}
																			prefix={
																				user_currency &&
																				currencies[user_currency.toUpperCase()]
																					.symbol
																			}
																			className='text-muted text-decoration-line-through'
																		/>
																		&nbsp;{' '}
																		<NumericFormat
																			value={discountedPrice.toFixed(2)}
																			displayType={'text'}
																			thousandSeparator={true}
																			prefix={
																				user_currency &&
																				currencies[user_currency.toUpperCase()]
																					.symbol
																			}
																			className='text-danger'
																		/>
																	</>
																)}
															{item.price === discountedPrice && (
																<NumericFormat
																	value={item.price.toFixed(2)}
																	displayType={'text'}
																	thousandSeparator={true}
																	prefix={
																		user_currency &&
																		currencies[user_currency.toUpperCase()]
																			.symbol
																	}
																/>
															)}
														</>
													)}
												</Col>
											</Row>
										</ListGroup.Item>
									);
								})}
							</ListGroup>
						)}
					</ListGroup.Item>
				</ListGroup>
			</Col>
			<Col md={4}>
				<Card>
					<ListGroup variant='flush'>
						<ListGroup.Item>
							<h2>Order Summary</h2>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col>Items</Col>
								<Col>
									{originalItemsPrice !== itemsPrice && (
										<>
											<NumericFormat
												value={originalItemsPrice}
												displayType={'text'}
												thousandSeparator={true}
												prefix={
													user_currency
														? currencies[user_currency.toUpperCase()].symbol
														: ''
												}
												className='text-muted text-decoration-line-through'
											/>
											&nbsp;{' '}
											<NumericFormat
												value={itemsPrice}
												displayType={'text'}
												thousandSeparator={true}
												prefix={
													user_currency
														? currencies[user_currency.toUpperCase()].symbol
														: ''
												}
												className='text-danger'
											/>
										</>
									)}
									{originalItemsPrice === itemsPrice && (
										<NumericFormat
											value={itemsPrice}
											displayType={'text'}
											thousandSeparator={true}
											prefix={
												user_currency
													? currencies[user_currency.toUpperCase()].symbol
													: ''
											}
										/>
									)}
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col>Shipping</Col>
								<Col>
									<NumericFormat
										value={shippingPrice}
										displayType={'text'}
										thousandSeparator={true}
										prefix={
											user_currency
												? currencies[user_currency.toUpperCase()].symbol
												: ''
										}
									/>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col>Tax</Col>
								<Col>
									<NumericFormat
										value={taxPrice}
										displayType={'text'}
										thousandSeparator={true}
										prefix={
											user_currency
												? currencies[user_currency.toUpperCase()].symbol
												: ''
										}
									/>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							<Row>
								<Col>Total</Col>
								<Col>
									<NumericFormat
										value={totalPrice}
										displayType={'text'}
										thousandSeparator={true}
										prefix={
											user_currency
												? currencies[user_currency.toUpperCase()].symbol
												: ''
										}
									/>
								</Col>
							</Row>
						</ListGroup.Item>
						<ListGroup.Item>
							{error && <Message variant='danger'>{error}</Message>}
						</ListGroup.Item>
						<ListGroup.Item>
							<Button
								type='button'
								className='btn-block'
								disabled={cart.cartItems.length === 0}
								onClick={placeOrderHandler}
							>
								Place Order
							</Button>
						</ListGroup.Item>
					</ListGroup>
				</Card>
			</Col>
		</Row>
	);
};

export default PlaceOrderScreen;
