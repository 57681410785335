import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const ForgotPasswordScreen = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			const { data } = await axios.post(
				'/api/users/forgot-password',
				{ email },
				config
			);
			setMessage(data.message);
		} catch (error) {
			setMessage('Error sending email');
		}
	};

	return (
		<Container className='mt-5'>
			<Row className='justify-content-md-center'>
				<Col xs={12} md={6}>
					<h1 className='mb-4'>Forgot Password</h1>
					{message && <Alert variant='info'>{message}</Alert>}
					<Form onSubmit={submitHandler}>
						<Form.Group controlId='email' className='mb-3'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type='email'
								placeholder='Enter email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Form.Group>
						<Button type='submit' variant='primary'>
							Send Reset Link
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default ForgotPasswordScreen;
