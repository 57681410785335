import React from 'react';
import { Form, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Message from './Message';
import Loader from './Loader';

const ProductDiscount = ({
	discountValue,
	setDiscountValue,
	discountUnit,
	setDiscountUnit,
	validUntil,
	setValidUntil,
	couponCode,
	setCouponCode,
	loadingDiscount,
	errorDiscount,
}) => {
	return (
		<Card className='my-3'>
			<Card.Header>
				<h5>Product Discount</h5>
			</Card.Header>
			<Card.Body>
				{loadingDiscount ? (
					<Loader />
				) : errorDiscount && errorDiscount !== 'Discount not found' ? (
					<Message variant='danger'>{errorDiscount}</Message>
				) : (
					<Form>
						<Form.Group controlId='discountValue'>
							<Form.Label>
								Discount Value<span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='number'
								placeholder='Enter discount value'
								value={discountValue}
								onChange={(e) => setDiscountValue(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='discountUnit'>
							<Form.Label>Discount Unit</Form.Label>
							<Form.Control
								as='select'
								value={discountUnit}
								onChange={(e) => setDiscountUnit(e.target.value)}
							>
								<option value='percentage'>Percentage</option>
								<option value='amount'>Amount</option>
							</Form.Control>
						</Form.Group>

						<Form.Group controlId='validUntil'>
							<Form.Label>
								Valid Until<span style={{ color: 'red' }}>*</span>
							</Form.Label>
							<Form.Control
								type='date'
								placeholder='Enter valid until date'
								value={validUntil}
								onChange={(e) => setValidUntil(e.target.value)}
							></Form.Control>
						</Form.Group>

						<Form.Group controlId='couponCode'>
							<Form.Label>
								Coupon Code
								<OverlayTrigger
									placement='right'
									overlay={
										<Tooltip id='tooltip-right'>
											If you don't put any code then discount will be
											immediately available to all users otherwise user has to
											put the code on the cart page to avail the discount.
										</Tooltip>
									}
								>
									<span
										style={{
											marginLeft: '5px',
											cursor: 'pointer',
											color: '#007bff',
										}}
									>
										<i className='fas fa-info-circle'></i>
									</span>
								</OverlayTrigger>
							</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter coupon code'
								value={couponCode}
								onChange={(e) => setCouponCode(e.target.value)}
							></Form.Control>
						</Form.Group>
					</Form>
				)}
			</Card.Body>
		</Card>
	);
};

export default ProductDiscount;
