import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormContainer from '../components/FormContainer';
import { savePaymentMethod } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutSteps';

const PaymentScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const cart = useSelector((state) => state.cart);

	const { shippingAddress, paymentMethod: savedPaymentMethod } = cart;

	const [paymentMethod, setPaymentMethod] = useState(
		savedPaymentMethod || 'RazorPay'
	);

	useEffect(() => {
		if (!shippingAddress.address) {
			navigate('/shipping');
		}
	}, [navigate, shippingAddress]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(savePaymentMethod(paymentMethod));
		navigate('/placeorder');
	};
	return (
		<FormContainer>
			<CheckoutSteps step1 step2 step3></CheckoutSteps>
			<h1>Payment Method</h1>
			<Form onSubmit={submitHandler}>
				<Form.Group>
					<Form.Label as='legend'>Select Method</Form.Label>

					<Col>
						<Form.Check
							type='radio'
							label='Credit Card / Net Banking / UPI etc.'
							id='RazorPay'
							name='paymentMethod'
							value='RazorPay'
							checked={paymentMethod === 'RazorPay'}
							onChange={(e) => setPaymentMethod(e.target.value)}
						></Form.Check>

						<Form.Check
							type='radio'
							label='Cash on Delivery (COD)'
							id='cashOnDelivery'
							name='paymentMethod'
							value='CashOnDelivery'
							checked={paymentMethod === 'CashOnDelivery'}
							onChange={(e) => setPaymentMethod(e.target.value)}
						></Form.Check>

						<Form.Check
							type='radio'
							label='Paypal'
							id='PayPal'
							name='paymentMethod'
							value='PayPal'
							checked={paymentMethod === 'PayPal'}
							onChange={(e) => setPaymentMethod(e.target.value)}
						></Form.Check>
					</Col>
				</Form.Group>
				<Button type='submit' variant='primary'>
					Continue
				</Button>
			</Form>
		</FormContainer>
	);
};

export default PaymentScreen;
