import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
//import axios from 'axios';

// TODO: Replace with actual categories from API and make sure its dynamic. Only show categories that have products.
const categories = [
	{
		name: 'Fashion & Accessories',
		subcategories: [
			'Women Bags',
			'Women Shoes',
			'Women Clothes',
			'Men Bags',
			'Men Clothes',
			'Men Shoes',
			'Fashion Accessories',
			'Muslim Fashion',
			'Baby & Kids Fashion',
			'Watches',
		],
	},
	{
		name: 'Electronics & Gadgets',
		subcategories: [
			'Mobile & Gadgets',
			'Computers & Accessories',
			'Gaming & Consoles',
			'Cameras & Drones',
			'Audio',
		],
	},
	{
		name: 'Home & Living',
		subcategories: ['Home & Living', 'Home Appliances'],
	},
	{
		name: 'Food & Beverage',
		subcategories: ['Food & Beverage'],
	},
	{
		name: 'Sports & Outdoors',
		subcategories: ['Sports & Outdoors'],
	},
	{
		name: 'Crafts & Books',
		subcategories: ['Hobbies & Collections', 'Books & Magazines', 'Stationery'],
	},
];

const Menu = () => {
	const [dropdownOpen, setDropdownOpen] = useState(null);
	const [filteredCategories, setFilteredCategories] = useState([]);

	useEffect(() => {
		const fetchProducts = async () => {
			try {
				//const { data } = await axios.get('/api/products'); // Fetch products from API
				//const products = data.products;

				//console.log('Fetched products:', products); // Debugging statement

				//const productCategories = products.map((product) => product.categoryId);
				// console.log('Fetched categories:', productCategories);
				//const uniqueCategories = [...new Set(productCategories)];

				//console.log('Unique categories:', uniqueCategories); // Debugging statement

				/*const filtered = categories
					.map((category) => {
						const filteredSubcategories = category.subcategories.filter(
							(subcategory) => uniqueCategories.includes(subcategory)
						);
						return { ...category, subcategories: filteredSubcategories };
					})
					.filter((category) => category.subcategories.length > 0);*/

				//console.log('Filtered categories:', filtered); // Debugging statement

				setFilteredCategories(categories); // Set filtered categories
			} catch (error) {
				console.error('Error fetching products:', error);
			}
		};

		fetchProducts(); // Call fetchProducts on component mount
	}, []);

	const handleMouseEnter = (index) => {
		setDropdownOpen(index);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(null);
	};

	return (
		<Navbar bg='light' expand='lg'>
			<Navbar.Toggle aria-controls='basic-navbar-nav' />
			<Navbar.Collapse id='basic-navbar-nav'>
				<Nav className='justify-content-center'>
					{filteredCategories.map((category, index) => (
						<NavDropdown
							key={category.name}
							title={category.name}
							id={`nav-dropdown-${index}`}
							show={dropdownOpen === index}
							onMouseEnter={() => handleMouseEnter(index)}
							onMouseLeave={handleMouseLeave}
						>
							{category.subcategories.map((subcategory, subIndex) => (
								<LinkContainer
									to={
										'categories/' +
										`${subcategory.replace(/\s+/g, '-').toLowerCase()}`
									}
									key={subIndex}
								>
									<NavDropdown.Item>{subcategory}</NavDropdown.Item>
								</LinkContainer>
							))}
						</NavDropdown>
					))}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default Menu;
