export const PRODUCT_DISCOUNT_REQUEST = 'PRODUCT_DISCOUNT_REQUEST';
export const PRODUCT_DISCOUNT_SUCCESS = 'PRODUCT_DISCOUNT_SUCCESS';
export const PRODUCT_DISCOUNT_FAIL = 'PRODUCT_DISCOUNT_FAIL';

export const PRODUCT_DISCOUNT_UPDATE_REQUEST =
	'PRODUCT_DISCOUNT_UPDATE_REQUEST';
export const PRODUCT_DISCOUNT_UPDATE_SUCCESS =
	'PRODUCT_DISCOUNT_UPDATE_SUCCESS';
export const PRODUCT_DISCOUNT_UPDATE_FAIL = 'PRODUCT_DISCOUNT_UPDATE_FAIL';

export const DISCOUNT_FETCH_REQUEST = 'DISCOUNT_FETCH_REQUEST';
export const DISCOUNT_FETCH_SUCCESS = 'DISCOUNT_FETCH_SUCCESS';
export const DISCOUNT_FETCH_FAIL = 'DISCOUNT_FETCH_FAIL';
