import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
	const { token } = useParams();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');

	const submitHandler = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setMessage('Passwords do not match');
			return;
		}

		try {
			console.log('Sending request with payload:', { password });
			const { data } = await axios.post(`/api/users/reset-password/${token}`, {
				password,
			});
			setMessage(data.message); // Assuming the response has a message field
		} catch (error) {
			console.log('Error response:', error.response);
			if (error.response?.data?.message) {
				setMessage(error.response.data.message);
			} else if (
				error.response?.status === 401 ||
				error.response?.data?.error === 'Invalid or expired token'
			) {
				setMessage('Invalid or expired token');
			} else {
				setMessage('Error resetting password');
			}
		}
	};

	return (
		<Container>
			<Row className='justify-content-md-center'>
				<Col xs={12} md={6}>
					<h1 className='my-4'>Reset Password</h1>
					{message && <Alert variant='info'>{message}</Alert>}
					<Form onSubmit={submitHandler}>
						<Form.Group controlId='password'>
							<Form.Label>New Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Enter new password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group controlId='confirmPassword'>
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type='password'
								placeholder='Confirm new password'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								required
							/>
						</Form.Group>
						<Button type='submit' variant='primary' className='mt-3'>
							Reset Password
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default ResetPassword;
