import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const HelpScreen = () => {
	return (
		<Container>
			<Row>
				<Col className='text-center py-3'>
					For any inquiries, please email to{' '}
					<a href='mailto:sow.trendy@gmail.com'>Sow Trendy Support</a> or
					Whatsapp us at +919811183052.
				</Col>
			</Row>
		</Container>
	);
};

export default HelpScreen;
