import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listOrdersSeller } from '../actions/orderActions';

const OrderListScreenSeller = () => {
	const dispatch = useDispatch();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const orderListSeller = useSelector((state) => state.orderListSeller);
	const { loading, error, orders } = orderListSeller;

	useEffect(() => {
		if (userInfo) {
			dispatch(listOrdersSeller(userInfo._id));
		}
	}, [dispatch, userInfo]);

	return (
		<>
			<h1>Orders</h1>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table striped bordered hover responsive className='table-sm'>
					<thead>
						<tr>
							<th>ORDER ID</th>
							<th>ITEM NAME</th>
							<th>TOTAL</th>
							<th>PAID</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((order) =>
							order.orderItems.map(
								(item) =>
									item.seller &&
									item.seller.toString() === userInfo._id && (
										<tr key={item._id}>
											<td>{order._id}</td>
											<td>
												<Link to={`/product/${item.product}`}>{item.name}</Link>
											</td>
											<td>
												{' '}
												{new Intl.NumberFormat('en-US', {
													style: 'currency',
													currency: item.currency,
												}).format(item.price * item.qty)}
											</td>
											<td>
												{order.isPaid ? (
													<i
														className='fas fa-check'
														style={{ color: 'green' }}
													></i>
												) : (
													<i
														className='fas fa-times'
														style={{ color: 'red' }}
													></i>
												)}
											</td>
										</tr>
									)
							)
						)}
					</tbody>
				</Table>
			)}
		</>
	);
};

export default OrderListScreenSeller;
