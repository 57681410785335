import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const Footer = () => {
	return (
		<footer>
			<Container>
				<Row>
					<Col className='text-center py-3 footer'>
						{' '}
						&copy;2024 <a href='https://combinedlogics.com'>Combined Logics</a>.
						All rights reserved{' | '}
						<span>
							<a href='/terms-conditions'>Terms & Conditions</a>
						</span>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
