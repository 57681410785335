import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { register } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import { currencies } from '../components/Currencies';
import { useNavigate, useLocation } from 'react-router-dom';

const RegisterScreen = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState(null);
	const [currency, setCurrency] = useState('USD');

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const userRegister = useSelector((state) => state.userRegister);
	const { loading, error, userInfo } = userRegister;
	const redirect = location.search ? location.search.split('=')[1] : '/';

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, userInfo, redirect]);
	const submitHandler = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setMessage('Passwords to not match');
		} else {
			dispatch(register(name, phone, email, currency, '', '', password));
		}
	};

	return (
		<FormContainer>
			<h1>Sign Up</h1>
			{message && <Message variant='danger'>{message}</Message>}
			{error && <Message variant='danger'>{error}</Message>}
			{loading && <Loader />}
			<Form onSubmit={submitHandler}>
				<Form.Group controlId='name' className='mb-3'>
					<Form.Label>Name</Form.Label>
					<Form.Control
						type='name'
						placeholder='Enter Name'
						value={name}
						onChange={(e) => setName(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='phone' className='mb-3'>
					<Form.Label>Mobile Number</Form.Label>
					<Form.Control
						type='tel'
						placeholder='Enter Mobile No.'
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='email' className='mb-3'>
					<Form.Label>Email Address</Form.Label>
					<Form.Control
						type='email'
						placeholder='Enter Email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						autoComplete='email'
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='currency' className='mb-3'>
					<Form.Label>Choose Country</Form.Label>
					<Form.Control
						as='select'
						value={currency}
						onChange={(e) => setCurrency(e.target.value)}
					>
						{Object.keys(currencies).map((item) => {
							return (
								<option
									value={currencies[item].code}
									key={currencies[item].code}
								>
									{currencies[item].country} - {currencies[item].code} (
									{currencies[item].symbol})
								</option>
							);
						})}
					</Form.Control>
				</Form.Group>

				<Form.Group controlId='password' className='mb-3'>
					<Form.Label>Password</Form.Label>
					<Form.Control
						type='password'
						placeholder='Enter Password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						autoComplete='new-password'
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='confirmPassword' className='mb-3'>
					<Form.Label>Re-type Password</Form.Label>
					<Form.Control
						type='password'
						placeholder='Confirm Password'
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						autoComplete='new-password'
					></Form.Control>
				</Form.Group>

				<Button type='submit' variant='primary'>
					Register
				</Button>
			</Form>
			<Row className='py-3'>
				<Col>
					<Form.Text muted>Have an Account? </Form.Text>

					<Link to={redirect ? `login?redirect=${redirect}` : '/login'}>
						Login
					</Link>
				</Col>
			</Row>
		</FormContainer>
	);
};

export default RegisterScreen;
