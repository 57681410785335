import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
	FacebookShareButton,
	FacebookIcon,
	FacebookMessengerShareButton,
	FacebookMessengerIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
	TelegramShareButton,
	TelegramIcon,
} from 'react-share';

export default function SocialShare({ product }) {
	const shareUrl = window.location.href;
	const title = product.name;
	const imageUrl = product.image ? product.image[0] : '';

	/*console.log(product);
	console.log(
		title + ' ' + imageUrl + ' ' + shareUrl + ' ' + product.description
	);*/

	return (
		<div className='socialShare'>
			<Helmet>
				<meta property='og:title' content={title} />
				<meta property='og:description' content={product.description} />
				<meta property='og:image' content={imageUrl} />
				<meta property='og:url' content={shareUrl} />
			</Helmet>
			<label>Share:</label>

			<FacebookShareButton url={shareUrl}>
				<FacebookIcon size={32} round />
			</FacebookShareButton>

			<FacebookMessengerShareButton url={shareUrl}>
				<FacebookMessengerIcon size={32} round />
			</FacebookMessengerShareButton>

			<TwitterShareButton url={shareUrl}>
				<TwitterIcon size={32} round />
			</TwitterShareButton>

			<WhatsappShareButton url={shareUrl}>
				<WhatsappIcon size={32} round />
			</WhatsappShareButton>

			<TelegramShareButton url={shareUrl}>
				<TelegramIcon size={32} round />
			</TelegramShareButton>
		</div>
	);
}
