import {
	PRODUCT_DISCOUNT_REQUEST,
	PRODUCT_DISCOUNT_SUCCESS,
	PRODUCT_DISCOUNT_FAIL,
	PRODUCT_DISCOUNT_UPDATE_REQUEST,
	PRODUCT_DISCOUNT_UPDATE_SUCCESS,
	PRODUCT_DISCOUNT_UPDATE_FAIL,
	DISCOUNT_FETCH_REQUEST,
	DISCOUNT_FETCH_SUCCESS,
	DISCOUNT_FETCH_FAIL,
} from '../constants/discountConstants';

export const productDiscountDetailsReducer = (
	state = { discount: {} },
	action
) => {
	switch (action.type) {
		case PRODUCT_DISCOUNT_REQUEST:
			return { loading: true, ...state };
		case PRODUCT_DISCOUNT_SUCCESS:
			return { loading: false, discount: action.payload };
		case PRODUCT_DISCOUNT_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const productDiscountUpdateReducer = (
	state = { discount: {} },
	action
) => {
	switch (action.type) {
		case PRODUCT_DISCOUNT_UPDATE_REQUEST:
			return { loading: true };
		case PRODUCT_DISCOUNT_UPDATE_SUCCESS:
			return { loading: false, success: true, discount: action.payload };
		case PRODUCT_DISCOUNT_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const productDiscountsListReducer = (
	state = { discounts: [] },
	action
) => {
	switch (action.type) {
		case DISCOUNT_FETCH_REQUEST:
			return { loading: true, discounts: [] };
		case DISCOUNT_FETCH_SUCCESS:
			return { loading: false, discounts: action.payload };
		case DISCOUNT_FETCH_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
